
<div class="login-container">

    <div class="login-logo">
      <img src="/assets/images/logo_1.png">
    </div>

  <span *ngIf="!autoConnect" >

    <form (ngSubmit)="doLogin()" [formGroup]="loginForm">

      <div class="login-frame mat-elevation-z6">

        <div *ngIf="isLoadingResults" class="loading">
            <mat-spinner strokeWidth=3 diameter=50 ></mat-spinner>
        </div>

        <div class="login-box">
          <!-- Username -->
          <mat-form-field appearance="outline">
            <mat-label>Uporabniško ime</mat-label>
            <input matInput tabindex="1" placeholder="Uporabniško ime" [formControl]="username" [errorStateMatcher]="matcher" required>
            <mat-error *ngIf="username.invalid">{{getUserErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="login-box">
          <!-- Password -->
          <mat-form-field appearance="outline">
            <mat-label>Geslo</mat-label>
            <input matInput tabindex="2" placeholder="Geslo" [type]="hide ? 'password' : 'text'"  [formControl]="password" [errorStateMatcher]="matcher" required>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="login-box-button">
          <!-- Submit button -->
          <button tabindex="3" type="submit" mat-flat-button color="primary" [disabled]="loginForm.invalid || isLoadingResults">Prijava</button>
        </div>
      </div>
    </form>
  </span>

  <!-- Auto connect -->
  <span *ngIf="autoConnect" >
    <div *ngIf="isLoadingResults" class="loading" style="padding-top: 200px;">
      <mat-spinner strokeWidth=3 diameter=50  ></mat-spinner>
    </div>
  </span>
</div>
