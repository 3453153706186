<!-- This is the main layout -->
<div class="full-page">
<mat-toolbar color="primary" class="toolbar-container">
    <button type="button" mat-button (click)="drawer.toggle()">
        <mat-icon >menu</mat-icon>
    </button>

  <!--span>{{title}}</span-->
  <div class="title-logo">
      <img src="/assets/images/l_2.png">
  </div>

  <!-- This fills the remaining space of the current row -->
  <span class="fill-remaining-space"></span>

  <span>
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon aria-hidden="true" >person</mat-icon>
      {{user}}
    </button>
    <mat-menu #menu="matMenu" xPosition="before" yPosition="below" overlapTrigger="false">
      <button mat-menu-item (click)="editPassword()">
        <mat-icon>visibility_off</mat-icon>
        <span>Spremeni geslo</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Odjava</span>
      </button>
    </mat-menu>
  </span>
</mat-toolbar>
<div class = "fill-height-or-more">
<mat-drawer-container class="drawer-container" autosize>
  <mat-drawer #drawer class="drawer-sidenav" mode="side" opened="true">
    <div class="full-page">
      <nav>
      <div>

        <div flex>
          <span matTooltip="Dokumenti" matTooltipPosition="right">
            <a routerLink="/web/work-order/dashboard" routerLinkActive="router-link-active">
              <button type="button" mat-button class="md-nav">
              <mat-icon aria-hidden="true" class="md-36">work</mat-icon>
              </button>
            </a>
          </span>
        </div>

        <div flex>
          <span matTooltip="Objekti" matTooltipPosition="right">
            <a routerLink="/web/customer/dashboard" routerLinkActive="router-link-active">
              <button type="button" mat-button class="md-nav">
              <mat-icon aria-hidden="true" class="md-36">apartment</mat-icon>
              </button>
            </a>
          </span>
        </div>

        <div flex *ngIf="isAdmin">
          <span matTooltip="Uporabniki" matTooltipPosition="right">
            <a routerLink="/web/admin" routerLinkActive="router-link-active">
            <button type="button" mat-button class="md-nav">
              <mat-icon aria-hidden="true" class="md-36">manage_accounts</mat-icon>
            </button>
            </a>
          </span>
        </div>

        <div flex *ngIf="isAdmin">
          <span matTooltip="Nastavitve" matTooltipPosition="right">
            <a routerLink="/web/settings/" routerLinkActive="router-link-active">
              <button type="button" mat-button class="md-nav">
              <mat-icon aria-hidden="true" class="md-36">settings</mat-icon>
              </button>
            </a>
          </span>
        </div>

      </div>
      </nav>

      <div class = "fill-height-or-more"></div>

      <div class = "server-info-frame">
        <span>Version: {{serverVersion}}</span>
        <span>Copyright &copy; 2018 - 2024 </span>
        <span>CapsLock d.o.o.</span>
      </div>

    </div>
  </mat-drawer>

  <router-outlet></router-outlet>

</mat-drawer-container>

</div>
