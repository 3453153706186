<div class="extend-window">

  <mat-drawer-container class="drawer-container" autosize>

  <mat-toolbar color="background">
    <span>Seznam dokumentov</span>

    <!-- This fills the remaining space of the current row -->
    <span class="fill-remaining-space"></span>

    <span>
      <mat-form-field class="filter-search" appearance="standard">
        <mat-label>Išči</mat-label>
        <input matInput  [formControl]="searchFilter" placeholder="Išči">
      </mat-form-field>
    </span>

    <span matTooltip="Osveži seznam dokumentov" matTooltipPosition="below">
        <button type="button" mat-icon-button (click)="onRefreshWorkOrders()">
          <mat-icon aria-hidden="true" >refresh</mat-icon>
        </button>
    </span>

    <span matTooltip="Dodaj nov dokument" matTooltipPosition="below" *ngIf="allowActions()">
      <button type="button" mat-icon-button (click)="onCreateWorkOrder()">
        <mat-icon aria-hidden="true" *ngIf="addWorkOrderComponents.length > 1" [matMenuTriggerFor]=menuAddWorkOrder>add_circle_outline</mat-icon>
        <mat-icon aria-hidden="true" *ngIf="addWorkOrderComponents.length < 2">add_circle_outline</mat-icon>
      </button>
    </span>

    <span matTooltip="Nastavi filtre" matTooltipPosition="below">
      <button type="button" mat-icon-button (click)="filterDrawer.toggle()">
        <mat-icon aria-hidden="true" >filter_list</mat-icon>
      </button>
    </span>

    <mat-menu #menuAddWorkOrder="matMenu" xPosition="before">
      <ng-template matMenuContent>
        <span *ngFor="let mitem of addWorkOrderComponents">
          <button mat-menu-item (click)="onCreateWorkOrderFromMenu(mitem)">
            <span>{{mitem.description}}</span>
        </button>
        </span>
      </ng-template>
    </mat-menu>

  </mat-toolbar>

  <div class="table-container">

    <div class="mat-elevation-z8">

      <div *ngIf="isLoadingResults" class="loading">
        <mat-spinner strokeWidth=5 ></mat-spinner>
      </div>

      <table mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows >

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox matBadge="{{selection.selected.length}}" matBadgeOverlap="false"
                          matBadgeSize="medium" matBadgeColor="primary" [matBadgeHidden]="!selection.hasValue()" color="primary"
                          (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox color="primary"
                          (click)="$event.stopPropagation()"
                          (change)="$event ? toggle(row) : null"
                          [checked]="selection.isSelected(row.id)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Order number Column -->
        <ng-container matColumnDef="orderNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Številka dokumenta </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
                {{element.orderNumber}}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Worked customer Column -->
        <ng-container matColumnDef="workedCustomer.name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Lokacija </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="multi-lines">
            <div>
              {{element.workedCustomer?.name}}
            </div>
            <div class="padding-top-5 address">
              {{element.workedCustomer?.address}}
            </div>
          </div>
          </mat-cell>
        </ng-container>

        <!-- Ordered customer Column -->
        <ng-container matColumnDef="orderedCustomer.name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Naročnik </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="multi-lines">
            <div>
              {{element.orderedCustomer?.name}}
            </div>
            <div class="padding-top-5 address">
              {{element.orderedCustomer?.address}}
            </div>
          </div>
          </mat-cell>
        </ng-container>

        <!-- Assigned user Column -->
        <ng-container matColumnDef="assignedUser.displayName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Dodeljen </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="isMultiUserAssigned(element)" matTooltipClass="md-11" matTooltip="{{getAssignedUserDisplayName(element, false)}}" matTooltipPosition="right">
              <mat-icon class="md-32 color-grey">group</mat-icon> &nbsp;
            </span>
            {{getAssignedUserDisplayName(element, true)}}
          </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
              <span>
                {{translateStatus(element.status)}}
              </span>
          </mat-cell>
        </ng-container>

        <!-- Priority Column -->
        <ng-container matColumnDef="priority">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Prioriteta </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span matTooltip="{{getPriorityLabel(element)}}" matTooltipPosition="above">
              <mat-icon class="md-32 priority-{{element.priority}}">{{getPriorityIcon(element)}}</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <!-- Badges Column -->
        <ng-container matColumnDef="badges">
          <mat-header-cell *matHeaderCellDef> Značke </mat-header-cell>
          <mat-cell *matCellDef="let element">

            <!-- Download file -->
            <span matTooltip="Prenesi dokument" matTooltipPosition="above">
              <button *ngIf="element.hasFile"  mat-icon-button (click)="$event.stopPropagation();onDownloadAttachment(element)">
                  <mat-icon color="accent">get_app</mat-icon>
              </button>
            </span>

            <!-- Note -->
            <span matTooltip="{{getNote(element)}}" matTooltipPosition="above">
              <button *ngIf="hasNote(element)" mat-icon-button disabled>
                <mat-icon color="accent">comment</mat-icon>
              </button>
            </span>

            <!-- Payable -->
            <span matTooltip="Nepotrjen" matTooltipPosition="above">
              <button *ngIf="isPayUnconfirmed(element)" mat-icon-button disabled>
                <mat-icon svgIcon="assignment-unconfirmed" class="color-red"></mat-icon>
              </button>
            </span>
            <span matTooltip="Potrjen" matTooltipPosition="above">
              <button *ngIf="isPayConfirmed(element)" mat-icon-button disabled>
                <mat-icon svgIcon="assignment-confirmed" color="accent"></mat-icon>
              </button>
            </span>
            <span matTooltip="Poslan" matTooltipPosition="above">
              <button *ngIf="isPaySent(element)" mat-icon-button disabled>
                <mat-icon svgIcon="assignment-sent" color="accent"></mat-icon>
              </button>
            </span>
            <span matTooltip="Ročni vnos" matTooltipPosition="above">
              <button *ngIf="isPayManual(element)" mat-icon-button disabled>
                <mat-icon svgIcon="assignment-manual" color="accent"></mat-icon>
              </button>
            </span>
            <span matTooltip="Zadržan" matTooltipPosition="above">
              <button *ngIf="isPayHold(element)" mat-icon-button disabled>
                <mat-icon svgIcon="assignment-hold" color="accent"></mat-icon>
              </button>
            </span>

          </mat-cell>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Tip </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
        </ng-container>

        <!-- Updated Column -->
        <ng-container matColumnDef="updated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Posodobljen </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated | date: dateFormatDt}} </mat-cell>
        </ng-container>

        <!-- Completed Column -->
        <ng-container matColumnDef="completed">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Dokončan </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.completed | date: dateFormat}} </mat-cell>
        </ng-container>

        <!-- Group action menu -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef [ngClass]="'actionClass'">

            <button mat-icon-button *ngIf="allowActionsForSelection()" [matMenuTriggerFor]="menuTitle">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menuTitle="matMenu">

              <!-- Assign selected -->
              <button mat-menu-item *ngIf="allowAssignForSelection()" (click)="onAssignSelection()">
                <mat-icon>assignment_ind</mat-icon>
                <span>Dodeli</span>
              </button>

              <!-- Cancel selected -->
              <button mat-menu-item *ngIf="allowCancelForSelection()" (click)="onCancelSelection()">
                <mat-icon>clear</mat-icon>
                <span>Prekliči</span>
              </button>

              <!-- Delete selected -->
              <button mat-menu-item *ngIf="allowDeleteForSelection()" (click)="onDeleteSelection()">
                <mat-icon>delete</mat-icon>
                <span>Izbriši</span>
              </button>
            </mat-menu>

          </mat-header-cell>

          <!-- Action menu -->
          <mat-cell *matCellDef="let element" [ngClass]="'actionClass'">

            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();"
                    *ngIf="element.id !== expandedElement?.id">
              <mat-icon>more_vert</mat-icon>
            </button>

            <!-- Payable actions submenu -->
            <mat-menu #PayableActionsMenu>
              <ng-template matMenuContent>
                <span *ngFor="let mitem of getWorkOrderActions(element, PayableActionsMenu).payableActions">
                  <button mat-menu-item (click)="mitem.action()">
                    <mat-icon svgIcon="{{mitem.svgIcon}}">{{mitem.icon}}</mat-icon>
                    <span>{{mitem.message}}</span>
                </button>
                </span>
              </ng-template>
            </mat-menu>

            <mat-menu #menu="matMenu">
              <ng-template matMenuContent>

                <!-- Display all available actions for this item -->
                <span *ngFor="let item of getWorkOrderActions(element, PayableActionsMenu).actions">
                  <button *ngIf="item.action" mat-menu-item (click)="item.action()">
                      <mat-icon svgIcon="{{item.svgIcon}}">{{item.icon}}</mat-icon>
                      <span>{{item.message}}</span>
                  </button>
                  <button *ngIf="item.menu" mat-menu-item [matMenuTriggerFor]=item.menu>
                    <mat-icon svgIcon="{{item.svgIcon}}">{{item.icon}}</mat-icon>
                    <span>{{item.message}}</span>
                  </button>
                </span>

              </ng-template>
            </mat-menu>

          </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail"
              [@detailExpand]="element.id === expandedElement?.id ? 'expanded' : 'collapsed'">
                <div class='details-column'
                  *ngIf="expandedElement?.id === element.id"
                  [@detailExpand]="element.id === expandedElement?.id ? 'expanded' : 'collapsed'" >

                  <div class='details-toolbar-row' *ngIf="allowActions()">

                    <!-- Payable actions submenu -->
                    <mat-menu #PayableActionsMenu>
                      <ng-template matMenuContent>
                        <span *ngFor="let mitem of getWorkOrderActions(element, PayableActionsMenu).payableActions">
                          <button mat-menu-item (click)="mitem.action()">
                            <mat-icon svgIcon="{{mitem.svgIcon}}">{{mitem.icon}}</mat-icon>
                            <span>{{mitem.message}}</span>
                        </button>
                        </span>
                      </ng-template>
                    </mat-menu>

                    <!-- Display all available actions for this work order -->
                    <span *ngFor="let item of getWorkOrderActions(element, PayableActionsMenu).actions">
                      <div matTooltip="{{item.message}}" matTooltipPosition="above">
                        <button *ngIf="item.action" mat-icon-button (click)="item.action()">
                          <mat-icon svgIcon="{{item.svgIcon}}" color="{{item.color}}">{{item.icon}}</mat-icon>
                        </button>
                        <button *ngIf="item.menu" mat-icon-button [matMenuTriggerFor]=item.menu>
                          <mat-icon svgIcon="{{item.svgIcon}}" color="{{item.color}}">{{item.icon}}</mat-icon>
                        </button>
                      </div>
                    </span>

                  </div>

                  <!-- Display work order details -->
                  <app-wo-detail [workOrder] = element [metaTypeMap] = metaTypeMap></app-wo-detail>
                </div>
              </div>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: displayedColumns;"
                  class="element-row"
                  [class.expanded-row]="expandedElement?.id === element.id"
                  (click)="expandedElement = expandedElement?.id === element.id ? null : element">
        </mat-row>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>

      <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 14, 50, 100, 500]">
      </mat-paginator>
    </div>
    &nbsp;

  </div>

    <mat-drawer #filterDrawer class="drawer-sidenav" mode="side" opened="false" position="end">

      <div class="filter-title">Filtri</div>

      <div class="form-container">

        <!-- Filter - updated after -->
        <mat-form-field class="filter-field" appearance="standard">
          <mat-label>Posodobljen po</mat-label>
          <input readonly
            matInput  [formControl]="updatedAfterDateFilter" [matDatepicker]="picker" placeholder="Posodobljen po"
            (focus)="picker.open()" (click)="picker.open()" (dateInput)="onUpdatedAfterDateChange('input', $event)" >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <button mat-icon-button *ngIf="updatedAfterDateFilter.value !== null" class="mat-filter-badge"
                    (click)="onUpdatedAfterDateClick();$event.stopPropagation()">
              <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
            </button>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- Filter - Work order type -->
        <mat-form-field class="filter-field" appearance="standard">
          <mat-label>Tip</mat-label>
          <mat-select placeholder="Tip" [formControl]="typeFilter" (selectionChange)="onTypeChange()" >
            <mat-option *ngFor="let type of typeList" [value]="type.key">{{type.value}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="typeFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();typeFilter.setValue(null);onTypeChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>

        <!-- Filter - Prioriteta -->
        <mat-form-field class="filter-field" appearance="standard">
          <mat-label> Prioriteta </mat-label>
          <mat-select [formControl]="priorityFilter" (selectionChange)="onPriorityChange()">
            <mat-option *ngFor="let p of priorityList" [value]="p.key">{{p.value}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="priorityFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();priorityFilter.setValue(null);onPriorityChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>

        <!-- Filter - Status -->
        <mat-form-field class="filter-field" appearance="standard">
          <mat-label>Status</mat-label>
          <mat-select placeholder="Status" [formControl]="statusFilter"  (selectionChange)="onStatusChange()" multiple >
            <mat-option *ngFor="let status of statusList" [value]="status.key">{{status.name}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="statusFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();statusFilter.setValue(null);onStatusChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>

        <!-- Filter - Payable -->
        <mat-form-field class="filter-field" appearance="standard" *ngIf="isPayableFilterEnabled()">
          <mat-label>Plačljivost</mat-label>
          <mat-select placeholder="Plačljivost" [formControl]="payableFilter"  (selectionChange)="onPayableChange()" >
            <mat-option *ngFor="let payable of payableList" [value]="payable.key">{{payable.name}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="payableFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();payableFilter.setValue(null);onPayableChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>

        <!-- Filter - sm -->
        <mat-form-field class="filter-field" appearance="standard" *ngIf="isSmFilterEnabled()">
          <mat-label>Stroškovno mesto</mat-label>
          <mat-select placeholder="Stroškovno mesto" [formControl]="smFilter"
                      [typeaheadDebounceInterval] = "500" (selectionChange)="onSmChange()">
            <mat-option *ngFor="let sm of smList" [value]="sm.key" style="font-size: 13px;">{{sm.value}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="smFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();smFilter.setValue(null);onSmChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>

        <!-- Filter - sn -->
        <mat-form-field class="filter-field" appearance="standard" *ngIf="isSnFilterEnabled()">
          <mat-label>Stroškovni nosilec</mat-label>
          <mat-select placeholder="Stroškovni nosilec" [formControl]="snFilter"
                     [typeaheadDebounceInterval] = "500" (selectionChange)="onSnChange()" >
            <mat-option *ngFor="let sn of snList" [value]="sn.key" style="font-size: 13px;">{{sn.value}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="snFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();snFilter.setValue(null);onSnChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>
    </div>

  </mat-drawer>

  </mat-drawer-container>
</div>
