
import { CustomerType } from '../../../models';

export enum DialogMode {
  createCustomer,
  editCustomer
}

export interface DialogData {
  customer: CustomerType;
  mode: DialogMode;
}
