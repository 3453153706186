<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">{{title}}</span>
</mat-toolbar>

<div class="form-container">

    <mat-form-field appearance="outline">
        <mat-label>Opomba</mat-label>
        <textarea matInput placeholder="Opomba" [formControl]="noteCtrl" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="10" maxlength="500" ></textarea>
    </mat-form-field>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] tabindex="-1">Prekliči</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="noteCtrl.value" tabindex="-1">Shrani</button>
    </mat-dialog-actions>

</div>
