
<mat-toolbar color="background">
  <span>Seznam uporabnikov</span>

  <!-- This fills the remaining space of the current row -->
  <span class="fill-remaining-space"></span>
  <span class="filter">
    <mat-form-field appearance="standard">
      <mat-label>Išči</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Išči">
    </mat-form-field>
  </span>

  <span matTooltip="Osveži seznam uporabnikov" matTooltipPosition="left">
    <button type="button" mat-icon-button (click)="refreshUsers()">
      <mat-icon aria-hidden="true" >refresh</mat-icon>
    </button>
  </span>
  <span matTooltip="Dodaj novega uporabnika" matTooltipPosition="left">
    <button type="button" mat-icon-button (click)="createUser()">
      <mat-icon aria-hidden="true" >add_circle_outline</mat-icon>
    </button>
  </span>
</mat-toolbar>

<div class="table-container">

  <div class="mat-elevation-z8">

    <div *ngIf="isLoadingResults" class="loading">
      <mat-spinner strokeWidth=5 ></mat-spinner>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Up. ime. </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
      </ng-container>

      <!-- Surname Column -->
      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Prikazano ime </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.displayName}} </mat-cell>
      </ng-container>

      <!-- Enabled Column -->
      <ng-container matColumnDef="enabled">
        <mat-header-cell *matHeaderCellDef> Aktiven </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <mat-checkbox disabled [(ngModel)]="element.enabled"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef [ngClass]="'actionClass'"></mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="'actionClass'">
          <button mat-button [matMenuTriggerFor]="menu"  (click)="$event.stopPropagation();">
            <mat-icon>more_vert</mat-icon>
          </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editPassword(element)">
                <mat-icon>visibility_off</mat-icon>
                <span>Spremeni geslo</span>
              </button>
              <button mat-menu-item (click)="delete(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Izbriši</span>
              </button>
            </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;"
                (click)="editUser(element)"
                class="element-row"></mat-row>
    </mat-table>
  </div>
  &nbsp;
</div>
