<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">{{title}}</span>
</mat-toolbar>

<div *ngIf="isLoadingResults" class="loading">
    <mat-spinner strokeWidth=3 diameter=50 ></mat-spinner>
</div>

<form (ngSubmit)="saveCustomer()" [formGroup]="customerForm" >

    <mat-dialog-content >
        <div class="form-container">

          <!-- Type -->
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Tip</mat-label>
            <mat-select name='typeControl' [formControl]="typeControl" placeholder="Tip" [errorStateMatcher]="matcher" required (selectionChange)="updateFields()">
              <mat-option *ngFor="let type of typeList" [value]="type.key">{{type.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="typeControl.invalid">{{getErrorMessage(typeControl)}}</mat-error>
          </mat-form-field>

            <!-- Name -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Ime in priimek / naziv</mat-label>
              <input matInput name='nameControl' placeholder="Ime in priimek / naziv" [formControl]="nameControl" [errorStateMatcher]="matcher" required>
              <mat-error *ngIf="nameControl.invalid">{{getErrorMessage(nameControl)}}</mat-error>
            </mat-form-field>

            <!-- Province -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Občina</mat-label>
              <input type="text" matInput placeholder="Občina" [formControl]="provinceControl" [matAutocomplete]="auto"
                (focus)="onActivateFormAnimations()" required>
              <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="onChanged()">
                  <mat-option *ngFor="let province of filteredProvinces | async " [value]="province">
                      <div class="two-line-option-first"> {{province.name}} </div>
                  </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="provinceControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
            </mat-form-field>

            <!-- City -->
            <div [className]="cityControl.disabled ? 'form-field-hidden' : 'form-field-expanded'"
                [@formControlAnimations]="cityControl.disabled ? 'collapsed' : 'expanded'" [@.disabled]="formControlAnimationsDisabled">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Naselje</mat-label>
                <input type="text" matInput placeholder="Naselje" [formControl]="cityControl" [matAutocomplete]="autoCity"
                  (focus)="onActivateFormAnimations()" >
                <mat-autocomplete #autoCity="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="onChanged()">
                    <mat-option *ngFor="let city of filteredCities | async" [value]="city">
                      <div class="two-line-option-first"> {{city.name}} </div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="cityControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
              </mat-form-field>
            </div>

          <!-- Street -->
          <div [className]="streetControl.disabled ? 'form-field-hidden' : 'form-field-expanded'"
              [@formControlAnimations]="streetControl.disabled ? 'collapsed' : 'expanded'" [@.disabled]="formControlAnimationsDisabled">
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Ulica</mat-label>
              <input type="text" matInput placeholder="Ulica" [formControl]="streetControl" [matAutocomplete]="autoStreet">
              <mat-autocomplete #autoStreet="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="onChanged()">
                  <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
                    <div class="two-line-option-first"> {{street.name}} </div>
                  </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="streetControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
            </mat-form-field>
          </div>

          <!-- House nr. -->
          <mat-form-field class="form-field" appearance="outline">
              <mat-label>Hišna št.</mat-label>
              <input matInput placeholder="Hišna št." [formControl]="numberControl">
          </mat-form-field>

          <!-- Address -->
          <mat-form-field class="form-field" appearance="outline" >
              <mat-label>Naslov</mat-label>
              <input matInput placeholder="Naslov" [formControl]="addressControl">
          </mat-form-field>

          <!-- Description -->
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Opomba</mat-label>
            <textarea matInput placeholder="Opomba" [formControl]="descriptionControl"matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="10" maxlength="150" ></textarea>
          </mat-form-field>

          <!-- Enabled -->
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Aktiven</mat-label>
            <mat-select name='typeControl' [formControl]="enabledControl" placeholder="Aktiven" [errorStateMatcher]="matcher" required>
              <mat-option [value]="true">Da</mat-option>
              <mat-option [value]="false">Ne</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] >Zapri </button>
        <button type="submit" mat-stroked-button color="primary" [disabled]="customerForm.invalid || isLoadingResults">Shrani</button>
    </mat-dialog-actions>

</form>
