<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">Dodajanje novega dokumenta</span>
</mat-toolbar>

<div *ngIf="isLoadingResults" class="loading">
    <mat-spinner strokeWidth=3 diameter=50 ></mat-spinner>
</div>

<form (ngSubmit)="saveWorkOrder()" [formGroup]="woForm" >

    <mat-dialog-content>
        <div class="form-container">

            <!-- Work order type (not needed)
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Tip</mat-label>
                <mat-select placeholder="Tip" [formControl]="typeControl" required>
                    <mat-option *ngFor="let type of typeList" [value]="type.key">{{type.value}}</mat-option>
                </mat-select>
            </mat-form-field> -->

            <!-- Linked document -->
            <div class="form-container-row">

                <!-- Document type -->
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{fieldMetaName('vezni_dokument')}}</mat-label>
                    <mat-select placeholder="{{fieldMetaName('vezni_dokument')}}" [formControl]="documentControl">
                        <mat-option *ngFor="let document of documentList" [value]="document.key">{{document.value}}</mat-option>
                        <mat-option>--- prazno ---</mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;&nbsp;

                <!-- Document number -->
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{fieldMetaName('vezni_dokument_st')}}</mat-label>
                    <input matInput placeholder="{{fieldMetaName('vezni_dokument_st')}}" [formControl]="documentNumberControl">
                </mat-form-field>

            </div>

            <!-- Priority -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label> Prioriteta </mat-label>
                <mat-select [formControl]="priorityControl">
                    <mat-option *ngFor="let p of priorityList" [value]="p.key">{{p.value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Planned date -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Planiran datum</mat-label>
                <input readonly
                    matInput  [formControl]="plannedDateControl" [matDatepicker]="picker" placeholder="Planiran datum" required
                    (click)="picker.open()" >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!-- SM -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{fieldMetaName('stroskovno_mesto')}}</mat-label>
                <mat-select placeholder="{{fieldMetaName('stroskovno_mesto')}}" [formControl]="smControl"
                    [typeaheadDebounceInterval] = "500" (selectionChange)="onSmChanged()" required>
                    <mat-option *ngFor="let sm of smList" [value]="sm.key">{{sm.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="smControl.invalid">Izberite eno od ponujenih polj</mat-error>
            </mat-form-field>

            <!-- SN -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>{{fieldMetaName('stroskovni_nosilec')}}</mat-label>
                <mat-select placeholder="{{fieldMetaName('stroskovni_nosilec')}}" [formControl]="snControl"
                            [typeaheadDebounceInterval] = "500" required>
                    <mat-option *ngFor="let sn of snList" [value]="sn.key">{{sn.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="snControl.invalid">Izberite eno od ponujenih polj</mat-error>
            </mat-form-field>

            <!-- Naročnik -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Stranka</mat-label>
                <input type="text" placeholder="Stranka" matInput [formControl]="partnerControl" [matAutocomplete]="auto" required>
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
                    <mat-option *ngFor="let partner of filteredPartners | async" [value]="partner" class="multiline-mat-option">
                        <div class="two-line-option-first"> {{partner.name}} </div>
                        <div class="two-line-option-second"> {{partner.address}} </div>
                        <div class="two-line-option-second"> {{partner.externalId}} </div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="partnerControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
            </mat-form-field>

            <div class="form-container-row">

                <!-- Davčna številka -->
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{fieldMetaName('narocnik_davcna')}}</mat-label>
                    <input matInput placeholder="{{fieldMetaName('narocnik_davcna')}}" [formControl]="taxNumberControl">
                </mat-form-field>
                &nbsp;&nbsp;

                <!-- Davčni zavezanec (DA/NE) -->
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>{{fieldMetaName('narocnik_zavezanec')}}</mat-label>
                    <mat-select placeholder="{{fieldMetaName('narocnik_zavezanec')}}" [formControl]="taxRequiredControl">
                        <mat-option *ngFor="let taxRequired of taxRequiredList" [value]="taxRequired.key">{{taxRequired.value}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <!-- Objekt - Odjemno mesto -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Lokacija</mat-label>
                <input type="text" placeholder="Lokacija" matInput [formControl]="locationControl" [matAutocomplete]="autoLoc">
                <mat-autocomplete #autoLoc="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
                    <mat-option *ngFor="let location of filteredLocations | async" [value]="location" class="multiline-mat-option">
                        <div class="two-line-option-first"> {{location.name}} </div>
                        <div class="two-line-option-second"> {{location.address}} </div>
                        <div class="two-line-option-second"> {{location.externalId}} </div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="partnerControl.errors?.notAnObject">Izberite eno od ponujenih polj, ali pustite prazno</mat-error>
            </mat-form-field>

            <!-- Intervencija (DA/NE) -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>{{fieldMetaName('intervencija')}}</mat-label>
              <mat-select placeholder="{{fieldMetaName('intervencija')}}" [formControl]="interventionControl">
                <mat-option *ngFor="let intervention of interventionList" [value]="intervention.key">{{intervention.value}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Opomba -->
            <mat-form-field appearance="outline">
                <mat-label>{{fieldMetaName('opomba')}}</mat-label>
                <textarea matInput placeholder="{{fieldMetaName('opomba')}}" [formControl]="noteControl" matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="10" maxlength="500" ></textarea>
            </mat-form-field>

        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] >Zapri </button>
        <button type="submit" mat-stroked-button color="primary" [disabled]="woForm.invalid">Shrani</button>
    </mat-dialog-actions>

</form>
