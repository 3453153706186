<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">{{title}}</span>
</mat-toolbar>

<div class="form-container">

    <div *ngIf="completed" class="alert">
        Dokument je dokončan! <br/> Ali ste prepričani, da želite dodeliti izbrani dokument?
    </div>

    <mat-form-field appearance="outline">
        <mat-label>Uporabniki</mat-label>
        <mat-select placeholder="Uporabniki" [formControl]="userCtrl" multiple>
          <mat-option *ngFor="let user of userList" [value]="user">{{user.displayName}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] tabindex="-1">Prekliči</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="userCtrl.value" tabindex="-1"
                [disabled]="userCtrl.value === null || userCtrl.value.length === 0" >Shrani</button>
    </mat-dialog-actions>

</div>
