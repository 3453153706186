<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">{{title}}</span>
</mat-toolbar>

<div *ngIf="isLoadingResults" class="loading">
    <mat-spinner strokeWidth=3 diameter=50 ></mat-spinner>
</div>

<form (ngSubmit)="saveUser()" [formGroup]="userForm" >

    <mat-dialog-content>
        <div class="form-container">

            <!-- Username -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Uporabniško ime</mat-label>
                <input matInput placeholder="Uporabniško ime" [formControl]="username" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="username.invalid">{{getErrorMessage(username)}}</mat-error>
            </mat-form-field>

            <!-- Name -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.changePassword" appearance="outline">
                <mat-label>Ime</mat-label>
                <input matInput placeholder="Ime" [formControl]="name" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="name.invalid">{{getErrorMessage(name)}}</mat-error>
            </mat-form-field>

            <!-- Surname -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.changePassword" appearance="outline">
                <mat-label>Priimek</mat-label>
                <input matInput placeholder="Priimek" [formControl]="surname" [errorStateMatcher]="matcher" required>
                <mat-error *ngIf="surname.invalid">{{getErrorMessage(surname)}}</mat-error>
            </mat-form-field>

            <!-- Display name -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.changePassword" appearance="outline">
                <mat-label>Prikazno ime</mat-label>
                <input matInput placeholder="Prikazno ime" [formControl]="displayName" [errorStateMatcher]="matcher" (focus)="onDisplayNameFocus()" required>
                <mat-error *ngIf="displayName.invalid">{{getErrorMessage(displayName)}}</mat-error>
            </mat-form-field>

            <!-- Password -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.editUser" appearance="outline">
                <mat-label>Geslo</mat-label>
                <input matInput placeholder="Geslo" [type]="hide ? 'password' : 'text'"  [formControl]="password" [errorStateMatcher]="matcher" required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                <mat-error *ngIf="password.invalid">{{getErrorMessage(password)}}</mat-error>
            </mat-form-field>

            <!-- Pin code -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.changePassword" appearance="outline">
                <mat-label>Pin koda</mat-label>
                <input matInput placeholder="Pin koda" [formControl]="pincode" maxlength="5">
            </mat-form-field>

            <!-- Pin code -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.changePassword" appearance="outline">
                <mat-label>Telefon</mat-label>
                <input matInput placeholder="Telefon" [formControl]="phone" maxlength="20">
            </mat-form-field>

            <!-- Roles -->
            <mat-form-field class="form-field" *ngIf="dialogData.mode !== dialogMode.changePassword" appearance="outline">
                <mat-label>Skupine</mat-label>
                <mat-select placeholder="Skupine" [formControl]="groups" multiple>
                    <mat-option *ngFor="let group of groupList" [value]="group">{{group}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Check boxes -->
            <div class="box" layout="row" *ngIf="dialogData.mode !== dialogMode.changePassword">

                <div class="item">
                    <!-- Enabled -->
                    <mat-checkbox name="enabled" [formControl]="enabled" color="primary">Aktiven</mat-checkbox>
                </div>

                <div class="item">
                    <!-- Public visible -->
                    <mat-checkbox name="publicVisible" [formControl]="publicVisible" value="unchecked" color="primary">Viden vsem</mat-checkbox>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] >Zapri </button>
        <button type="submit" mat-stroked-button color="primary" [disabled]="userForm.invalid || isLoadingResults">Shrani</button>
    </mat-dialog-actions>

</form>
