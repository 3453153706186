<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">Dodajanje novega dokumenta</span>
</mat-toolbar>

<div *ngIf="isLoadingResults" class="loading">
    <mat-spinner strokeWidth=3 diameter=50 ></mat-spinner>
</div>

<form (ngSubmit)="saveWorkOrder()" [formGroup]="woForm" >

    <mat-dialog-content>
        <div class="form-container">

            <!-- Planned date -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Planiran datum</mat-label>
                <input readonly
                    matInput  [formControl]="plannedDateControl" [matDatepicker]="picker" placeholder="Planiran datum" required
                    (click)="picker.open()" >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!-- Hidrant -->
            <mat-form-field class="form-field" appearance="outline">
                <mat-label>Hidrant</mat-label>
                <input type="text" placeholder="Hidrant" matInput [formControl]="hydrantControl" [matAutocomplete]="auto" required>
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn">
                    <mat-option *ngFor="let partner of filteredPartners | async" [value]="partner" class="multiline-mat-option">
                        <div class="two-line-option-first"> {{partner.name}} </div>
                        <div class="two-line-option-second"> {{partner.address}} </div>
                        <div class="two-line-option-second"> {{partner.externalId}} </div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="hydrantControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
            </mat-form-field>

            <!-- Opomba -->
            <mat-form-field appearance="outline">
                <mat-label>{{fieldMetaName('opomba')}}</mat-label>
                <textarea matInput placeholder="{{fieldMetaName('opomba')}}" [formControl]="noteControl" matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="10" maxlength="500" ></textarea>
            </mat-form-field>

        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] >Zapri </button>
        <button type="submit" mat-stroked-button color="primary" [disabled]="woForm.invalid">Shrani</button>
    </mat-dialog-actions>

</form>
