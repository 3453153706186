<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">{{title}}</span>
</mat-toolbar>

<div class="form-container">

    <mat-form-field appearance="outline">
        <mat-label>Prioriteta</mat-label>
        <mat-select [formControl]="priorityControl">
            <mat-option *ngFor="let p of priorityList" [value]="p.key">{{p.value}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] tabindex="-1">Prekliči</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="priorityControl.value" tabindex="-1"
                [disabled]="priorityControl.value === null || priorityControl.value.length === 0" >Shrani</button>
    </mat-dialog-actions>

</div>
