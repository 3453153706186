
<div class="extend-window">
  <mat-drawer-container class="drawer-container" autosize>
    <mat-toolbar color="background">
      <span>Seznam objektov</span>

      <!-- This fills the remaining space of the current row -->
      <span class="fill-remaining-space"></span>
      <span class="filter">
        <mat-form-field class="filter-search" appearance="standard">
          <mat-label>Išči</mat-label>
          <input matInput  [formControl]="searchFilter" placeholder="Išči">
        </mat-form-field>
      </span>

      <span matTooltip="Osveži seznam uporabnikov" matTooltipPosition="left">
        <button type="button" mat-icon-button (click)="refreshCustomers()">
          <mat-icon aria-hidden="true" >refresh</mat-icon>
        </button>
      </span>

      <span matTooltip="Dodaj nov objekt" matTooltipPosition="left" *ngIf="allowAdd()">
        <button type="button" mat-icon-button (click)="createCustomer()">
          <mat-icon aria-hidden="true" >add_circle_outline</mat-icon>
        </button>
      </span>

      <span matTooltip="Nastavi filtre" matTooltipPosition="left">
        <button type="button" mat-icon-button (click)="filterDrawer.toggle()">
          <mat-icon aria-hidden="true" >filter_list</mat-icon>
        </button>
      </span>
    </mat-toolbar>

    <div class="table-container">

      <div class="mat-elevation-z8">

        <div *ngIf="isLoadingResults" class="loading">
          <mat-spinner strokeWidth=5 ></mat-spinner>
        </div>

        <mat-table #table [dataSource]="dataSource" matSort>

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Customer name -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Ime </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>
                  {{element.name}}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Customer address -->
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Naslov </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="multi-lines">
              <div>
                {{element.address}}
              </div>
            </div>
            </mat-cell>
          </ng-container>

          <!-- Customer description -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Opomba </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="multi-lines">
              <div>
                {{element.description}}
              </div>
            </div>
            </mat-cell>
          </ng-container>

          <!-- Badges Column -->
          <ng-container matColumnDef="badges">
            <mat-header-cell *matHeaderCellDef> Značke </mat-header-cell>
            <mat-cell *matCellDef="let element">

              <!-- Picture -->
              <span matTooltip="Prenesi fotografijo" matTooltipPosition="above">
                <button *ngIf="element.fileId !== undefined" mat-icon-button (click)="$event.stopPropagation();onDownloadAttachment(element)">
                  <mat-icon color="accent" class="material-icons-outlined">image</mat-icon>
                </button>
              </span>

            </mat-cell>
          </ng-container>

          <!-- Customer type -->
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Tip </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
          </ng-container>

          <!-- Customer external ID -->
          <ng-container matColumnDef="externalId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.externalId}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef [ngClass]="'actionClass'" ></mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="'actionClass'">
              <button mat-button [matMenuTriggerFor]="menu"  (click)="$event.stopPropagation();">
                <mat-icon>more_vert</mat-icon>
              </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="delete(element)" [disabled]="!canEditOrDeleteCustomer(element)">
                      <mat-icon>delete</mat-icon>
                      <span>Izbriši</span>
                  </button>
                </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns;"
                    (click)="editCustomer(element)"
                    class="element-row"></mat-row>

        </mat-table>

        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[10, 14, 50, 100, 500]">
        </mat-paginator>
      </div>
      &nbsp;
    </div>

    <mat-drawer #filterDrawer class="drawer-sidenav" mode="side" opened="false" position="end">

      <div class="filter-title">Filtri</div>

      <div class="form-container">
        <!-- Filter - Customer type -->
        <mat-form-field class="filter-field" appearance="standard">
          <mat-label>Tip</mat-label>
          <mat-select placeholder="Tip" [formControl]="typeFilter" (selectionChange)="onTypeChange()" >
            <mat-option *ngFor="let type of typeList" [value]="type.key">{{type.value}}</mat-option>
          </mat-select>
          <button mat-icon-button *ngIf="typeFilter.value !== null" class="mat-filter-badge"
                  (click)="$event.stopPropagation();typeFilter.setValue(null);onTypeChange()">
            <mat-icon color="primary" class="md-round-14 color-primary">cancel</mat-icon>
          </button>
        </mat-form-field>

        <!-- Filter - Customer enabled -->
        <mat-form-field class="filter-enabled" appearance="standard">
          <mat-label>Aktivni</mat-label>
          <mat-select name='typeControl' [formControl]="enabledFilter" placeholder="Tip" (selectionChange)="onEnabledFilterChange()">
            <mat-option [value]="'all'">Vsi</mat-option>
            <mat-option [value]="'enabled'">Aktivni</mat-option>
            <mat-option [value]="'disabled'">Neaktivni</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-drawer>

  </mat-drawer-container>
</div>
