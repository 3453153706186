<mat-toolbar color="primary" class="toolbar-container">
    <span class="title-center">{{title}}</span>
</mat-toolbar>

<div *ngIf="isLoadingResults" class="loading">
    <mat-spinner strokeWidth=3 diameter=50 ></mat-spinner>
</div>

<form (ngSubmit)="saveCustomer()" [formGroup]="customerForm" >

    <mat-dialog-content >
        <div class="form-container">

              <!-- Name -->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Naziv</mat-label>
                <input matInput placeholder="Naziv" [formControl]="nameControl" required readonly>
                <mat-error *ngIf="nameControl.invalid">{{getErrorMessage(nameControl)}}</mat-error>
              </mat-form-field>

              <!-- LastCheckedDate -->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Datum zadnjega pregleda</mat-label>
                <input matInput placeholder="Datum zadnjega pregleda" [formControl]="lastCheckedDateControl" readonly>
              </mat-form-field>

              <!-- condition -->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Stanje</mat-label>
                <mat-select name='conditionControl' placeholder="Stanje" [formControl]="conditionControl" required>
                  <mat-option *ngFor="let condition of conditionList" [value]="condition.key">{{condition.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="conditionControl.invalid">{{getErrorMessage(conditionControl)}}</mat-error>
              </mat-form-field>

              <!-- Province -->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Občina</mat-label>
                <input type="text" matInput placeholder="Občina" [formControl]="provinceControl" [matAutocomplete]="auto"
                  (focus)="onActivateFormAnimations()" required>
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="onChanged()">
                    <mat-option *ngFor="let province of filteredProvinces | async " [value]="province">
                        <div class="two-line-option-first"> {{province.name}} </div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="provinceControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
              </mat-form-field>

              <!-- City -->
              <div [className]="cityControl.disabled ? 'form-field-hidden' : 'form-field-expanded'"
                  [@formControlAnimations]="cityControl.disabled ? 'collapsed' : 'expanded'" [@.disabled]="formControlAnimationsDisabled">
                <mat-form-field class="form-field" appearance="outline">
                  <mat-label>Naselje</mat-label>
                  <input type="text" matInput placeholder="Naselje" [formControl]="cityControl" [matAutocomplete]="autoCity"
                    (focus)="onActivateFormAnimations()" required>
                  <mat-autocomplete #autoCity="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="onChanged()">
                      <mat-option *ngFor="let city of filteredCities | async" [value]="city">
                        <div class="two-line-option-first"> {{city.name}} </div>
                      </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="cityControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
                </mat-form-field>
              </div>

            <!-- Street -->
            <div [className]="streetControl.disabled ? 'form-field-hidden' : 'form-field-expanded'"
                [@formControlAnimations]="streetControl.disabled ? 'collapsed' : 'expanded'" [@.disabled]="formControlAnimationsDisabled">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Ulica</mat-label>
                <input type="text" matInput placeholder="Ulica" [formControl]="streetControl" [matAutocomplete]="autoStreet" required>
                <mat-autocomplete #autoStreet="matAutocomplete" autoActiveFirstOption [displayWith]="displayFn" (optionSelected)="onChanged()">
                    <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
                      <div class="two-line-option-first"> {{street.name}} </div>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="streetControl.errors?.notAnObject">Izberite eno od ponujenih polj</mat-error>
              </mat-form-field>
            </div>

            <!-- Location -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Lokacija dodatno</mat-label>
              <input matInput placeholder="Lokacija dodatno" [formControl]="locationControl">
            </mat-form-field>

            <!-- Address -->
            <mat-form-field class="form-field" appearance="outline" >
                <mat-label>Naslov</mat-label>
                <input matInput placeholder="Naslov" [formControl]="addressControl" required>
                <mat-error *ngIf="addressControl.invalid">{{getErrorMessage(nameControl)}}</mat-error>
            </mat-form-field>

            <!-- Position -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Pozicija</mat-label>
              <mat-select placeholder="Pozicija" [formControl]="positionControl" required>
                  <mat-option *ngFor="let position of positionList" [value]="position.key">{{position.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="positionControl.invalid">{{getErrorMessage(positionControl)}}</mat-error>
            </mat-form-field>

            <!-- Type -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Vrsta</mat-label>
              <mat-select placeholder="Vrsta" [formControl]="hidTypeControl" required>
                  <mat-option *ngFor="let hidType of hidTypeList" [value]="hidType.key">{{hidType.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hidTypeControl.invalid">{{getErrorMessage(hidTypeControl)}}</mat-error>
            </mat-form-field>

            <!-- DN -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>DN (mm)</mat-label>
              <mat-select placeholder="DN (mm)" [formControl]="dnControl" required>
                  <mat-option *ngFor="let dn of dnList" [value]="dn.key">{{dn.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="dnControl.invalid">{{getErrorMessage(dnControl)}}</mat-error>
            </mat-form-field>

            <div class="form-container-row">

              <!-- Hid direction (Levo/Desno) -->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Smer hidranta</mat-label>
                <mat-select placeholder="Smer hidranta" [formControl]="hidDirectionControl">
                    <mat-option *ngFor="let hidDirection of hidDirectionList" [value]="hidDirection.key">{{hidDirection.value}}</mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;&nbsp;

              <!-- Hid distance -->
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Oddaljenost hidranta (m)</mat-label>
                <input matInput type="number" onwheel="this.blur()" placeholder="Oddaljenost hidranta (m)" [formControl]="hidDistanceControl">
              </mat-form-field>

            </div>

            <!-- Hid horizontal distance -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Horizontalna oddaljenost hidranta (m)</mat-label>
              <input matInput  type="number" onwheel="this.blur()" placeholder="Horizontalna oddaljenost hidranta (m)" [formControl]="hidHorizontalDistanceControl">
            </mat-form-field>

            <!-- zav -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Zavezanec</mat-label>
              <input matInput placeholder="Zavezanec" [formControl]="zavControl" required>
              <mat-error *ngIf="zavControl.invalid">{{getErrorMessage(zavControl)}}</mat-error>
            </mat-form-field>

            <!-- Rajon -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Rajon</mat-label>
              <mat-select placeholder="Rajon" [formControl]="rajonControl" required>
                <mat-option *ngFor="let rajon of rajonList" [value]="rajon.key">{{rajon.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="rajonControl.invalid">{{getErrorMessage(rajonControl)}}</mat-error>
            </mat-form-field>

            <!-- Description -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Opomba</mat-label>
              <textarea matInput placeholder="Opomba" [formControl]="descriptionControl"matTextareaAutosize matAutosizeMinRows="1" matAutosizeMaxRows="10" maxlength="150" ></textarea>
            </mat-form-field>

            <!-- Enabled -->
            <mat-form-field class="form-field" appearance="outline">
              <mat-label>Aktiven</mat-label>
              <mat-select name='typeControl' [formControl]="enabledControl" placeholder="Aktiven" [errorStateMatcher]="matcher" required>
                <mat-option [value]="true">Da</mat-option>
                <mat-option [value]="false">Ne</mat-option>
              </mat-select>
            </mat-form-field>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-stroked-button color="primary" [mat-dialog-close] >Zapri </button>
        <button type="submit" mat-stroked-button color="primary" [disabled]="customerForm.invalid || isLoadingResults">Shrani</button>
    </mat-dialog-actions>

</form>
