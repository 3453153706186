<div *ngIf="isLoadingResults; else loaded" class="loading">
        <mat-spinner strokeWidth=5 ></mat-spinner>
</div>

<ng-template #loaded>

    <div class=download-container>
        <div class="download-frame mat-elevation-z6">                
            
            <div *ngIf="isDownloadable(); else noFile">
                <div>
                    <button type="button" class="align-center" mat-icon-button (click)="onDownloadDocument()"> 
                        <mat-icon color="primary" class="download-icon" >cloud_download</mat-icon>
                    </button> 
                </div>
                <div class="download-text">
                    {{ idParam }}
                </div>
            </div>

            <ng-template #noFile>
                <div>
                    <button type="button" class="align-center" mat-icon-button disabled> 
                        <mat-icon class="download-icon color-red" >error_outilne</mat-icon>
                    </button> 
                </div>
                <div class="download-text">
                    {{ idParam }}
                </div>
                <div class="error-text">
                    {{errorText}}
                </div>
            </ng-template>
            
        </div>
    </div>

</ng-template>
